@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.unsupported-browser-container {
  position: relative;
  min-height: 100vh;
}

.unsupported-browser-wrap {
  padding-bottom: tokens.bpk-spacing-xxl();
}

.legal-info-ub-wrap {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: tokens.bpk-spacing-xxl();
}
